<script setup lang="ts">
import { useForm } from "vee-validate";
import * as z from "zod";

import type { Mark } from "@tiptap/pm/model";
import type { Editor } from "@tiptap/vue-3";
import { toTypedSchema } from "@vee-validate/zod";

const { editor } = defineProps<{
  editor?: Editor;
}>();

const store = useEditorStore();
const { showLinkDialog } = storeToRefs(store);

function getSelection(editor?: Editor) {
  if (!editor) return "";
  const { view, state } = editor;
  const { from, to } = view.state.selection;
  return state.doc.textBetween(from, to, "");
}

const { handleSubmit, values } = useForm({
  initialValues: {
    text: getSelection(editor),
    link: editor?.getAttributes("link")?.href || ""
  },
  validationSchema: toTypedSchema(
    z.object({
      text: z.optional(z.string()),
      link: z.optional(z.string())
    })
  ),
  initialTouched: {
    text: true,
    link: true
  },
  validateOnMount: true
});

function validateLink(link: string) {
  if (!link.startsWith("http")) {
    return `https://${link}`;
  }
  return link;
}

const onSubmit = handleSubmit((values) => {
  if (!editor) return;

  const { text, link } = values;
  if (!link) {
    editor.chain().focus().unsetLink().run();
    return;
  }

  editor
    .chain()
    .focus()
    .extendMarkRange("link")
    .command(({ tr, dispatch }) => {
      if (!dispatch) return true;
      const { from, to } = tr.selection;
      const displayText = text || link || "";
      tr.insertText(displayText, from, to);
      tr.addMark(
        from,
        from + displayText.length,
        editor?.schema.marks.link.create({ href: validateLink(link) }) as Mark
      );
      return true;
    })
    .run();

  showLinkDialog.value = false;
});

function onRemove() {
  editor?.commands.unsetLink();
  showLinkDialog.value = false;
}
</script>

<template>
  <BasicDialog v-model:open="showLinkDialog" :title="$t('editor.toolbar.link')">
    <form class="flex flex-col gap-4" @submit="onSubmit">
      <BasicFormField v-model="values.text" label="editor.link.text" name="text" />
      <BasicFormField v-model="values.link" label="editor.link.link" name="link" autofocus />
      <div class="flex gap-2">
        <BasicButton type="submit" text="buttons.save" />
        <BasicButton text="buttons.remove" @click.prevent.stop="onRemove" />
      </div>
    </form>
  </BasicDialog>
</template>
