<script setup lang="ts">
import { useForm } from "vee-validate";
import * as z from "zod";

import type { Editor } from "@tiptap/vue-3";
import { toTypedSchema } from "@vee-validate/zod";

const { editor } = defineProps<{
  editor?: Editor;
}>();

const store = useEditorStore();
const { showYoutubeDialog } = storeToRefs(store);

const { handleSubmit, values } = useForm({
  initialValues: {
    url: editor?.getAttributes("youtube")?.src || ""
  },
  validationSchema: toTypedSchema(
    z.object({
      url: z.optional(z.string())
    })
  ),
  initialTouched: {
    url: true
  },
  validateOnMount: true
});

const onSubmit = handleSubmit((values) => {
  if (!editor) return;

  const { url } = values;

  editor.commands.setYoutubeVideo({
    src: url || ""
  });

  showYoutubeDialog.value = false;
});
function onRemove() {
  editor?.commands.deleteSelection();
  showYoutubeDialog.value = false;
}
</script>

<template>
  <BasicDialog v-model:open="showYoutubeDialog" :title="$t('editor.toolbar.youtube')">
    <form class="flex flex-col gap-4" @submit="onSubmit">
      <BasicFormField v-model="values.url" label="editor.youtube.url" name="url" autofocus />
      <div class="flex gap-2">
        <BasicButton type="submit" text="buttons.save" />
        <BasicButton text="buttons.remove" @click.prevent.stop="onRemove" />
      </div>
    </form>
  </BasicDialog>
</template>
