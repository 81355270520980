<script setup lang="ts">
import type { Editor } from "@tiptap/vue-3";

import LinkForm from "./LinkForm.vue";
import YoutubeForm from "./YoutubeForm.vue";

const { editor, showHeadings, showYoutube } = defineProps<{
  editor?: Editor;
  isThread?: boolean;
  class?: string;
  showHeadings?: boolean;
  showYoutube?: boolean;
}>();

const store = useEditorStore();
const { showLinkDialog, showYoutubeDialog } = storeToRefs(store);

const buttons = [
  ...(showHeadings
    ? [
        {
          type: "heading",
          typeOptions: { level: 2 },
          tooltip: "editor.toolbar.h2",
          icon: "lucide-heading-2",
          action: () => editor?.chain().focus().toggleHeading({ level: 2 }).run()
        },
        {
          type: "heading",
          typeOptions: { level: 3 },
          tooltip: "editor.toolbar.h3",
          icon: "lucide-heading-3",
          action: () => editor?.chain().focus().toggleHeading({ level: 3 }).run()
        },
        {
          tooltip: "editor.toolbar.horizontalRule",
          icon: "lucide-minus",
          action: () => editor?.chain().focus().setHorizontalRule().run()
        }
      ]
    : []),
  {
    type: "bold",
    tooltip: "editor.toolbar.bold",
    icon: "ri-bold",
    action: () => editor?.chain().focus().toggleBold().run()
  },
  {
    type: "italic",
    icon: "ri-italic",
    tooltip: "editor.toolbar.italic",
    action: () => editor?.chain().focus().toggleItalic().run()
  },
  {
    type: "strike",
    icon: "ri-strikethrough",
    tooltip: "editor.toolbar.strikethrough",
    action: () => editor?.chain().focus().toggleStrike().run()
  },
  {
    type: "bulletList",
    icon: "ri-list-unordered",
    tooltip: "editor.toolbar.bulletList",
    action: () => editor?.chain().focus().toggleBulletList().run()
  },
  {
    type: "orderedList",
    icon: "ri-list-ordered",
    tooltip: "editor.toolbar.orderedList",
    action: () => editor?.chain().focus().toggleOrderedList().run()
  },
  {
    type: "link",
    icon: "ri-link",
    tooltip: "editor.toolbar.link",
    action: () => {
      showLinkDialog.value = true;
    }
  },
  {
    type: "blockquote",
    icon: "ri-double-quotes-l",
    tooltip: "editor.toolbar.blockquote",
    action: () => editor?.chain().focus().toggleBlockquote().run()
  },
  {
    icon: "ri-format-clear",
    tooltip: "editor.toolbar.clear",
    action: () => editor?.chain().focus().unsetAllMarks().clearNodes().run()
  },
  ...(showYoutube
    ? [
        {
          type: "youtube",
          icon: "ri-youtube-line",
          tooltip: "editor.toolbar.youtube",
          action: () => {
            showYoutubeDialog.value = true;
          }
        }
      ]
    : [])
];

function handleClick(event: MouseEvent, action: () => void) {
  event.stopImmediatePropagation();
  action();
}
</script>

<template>
  <ToolbarRoot :class="cn('flex gap-0.5 focus:ring-0', $props.class)" aria-label="Formatting options">
    <ToolbarButton v-for="{ icon, type, typeOptions, tooltip, action } in buttons" :key="icon" asChild>
      <BasicButton
        size="sm"
        variant="white"
        :icon
        :tooltip
        :class="
          cn('[&>svg]:text-neutral-7 border-none', {
            'bg-neutral-3': type && editor?.isActive(type, typeOptions)
          })
        "
        @click="(event: MouseEvent) => handleClick(event, action)"
      />
    </ToolbarButton>
    <LinkForm v-if="showLinkDialog" :editor />
    <YoutubeForm v-if="showYoutubeDialog" :editor />
  </ToolbarRoot>
</template>
